import { BlocksContent, Breadcrumb, Html, Image, Video } from "@global";
import {
  BreadcrumbPageContext,
  isSanityFullWidthTextBlock,
  isSanityGallery,
  isSanityHtml,
  isSanityImageTextBlock,
  isSanityImageWithMeta,
  isSanityVideo,
} from "@util/types";
import { Container, H1, H3, P, PaperContainer } from "@util/standard";
import {
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  assets,
  colors,
  fontSizes,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Maybe, Query, SanityBlogConnection } from "@graphql-types";
import { pageWidthMargin } from "@util/helper";

import CategoryPreview from "@shared/categoryPreview";
import GalleryCarousel from "@shared/galleryCarousel";
import Layout from "@shared/layout";
import React from "react";
import RecipePreview from "@shared/recipePreview";
import SEO from "@shared/seo";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useStore } from "@state/store";
import BlogPreview from "@components/blogs/blogPreview";

import { handleSaveRecipeProduct } from "@util/getFirebase";

const BreadcrumbContainer = styled(Container)`
  margin-top: 100px;
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 50px;
  }
`;

const Heading = styled(Container)`
  flex-direction: column;
  height: 100%;
  width: 90%;
  margin: 80px auto auto auto;
  p {
    margin-block-start: 0px;
    margin-block-end: 10px;
  }

  .heading-text {
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .heading-text {
      flex-direction: column;
      align-items: flex-start;
      h1 {
        margin: 0;
      }
      p {
        margin: 20px 0;
      }
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 40px;
  }
`;

const ImageTextBlockContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
  backgroundColour?: Maybe<string> | undefined;
}>`
  width: 90%;
  margin: auto;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  background-color: ${props => props.backgroundColour};
  padding: 30px 10px;
  align-items: center;
  justify-content: space-between;

  [data-gatsby-image-wrapper] {
    margin-top: 15px;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0;
    width: 100%;
  }
`;

const Wrapper = styled.div``;

const ContentBlocksContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
}>`
  width: 55%;
  justify-content: ${({ reverse }) => (reverse ? "flex-start" : "flex-end")};
  /* margin: 3% 0; */
  .block-content {
    width: 70%;
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 90%;
    margin: auto;

    .block-content {
      width: 100%;
    }
  }
`;

const ImageContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
}>`
  width: ${props => (props.reverse ? "50%" : "45%")};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const HeartIcon = styled(Container)`
  background-color: ${colors.green};
  width: 38px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 100px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    right: 20px;
  }
`;
interface QueryData extends Query {
  relatedblog: SanityBlogConnection;
}
interface Props {
  data: QueryData;
  pageContext: BreadcrumbPageContext;
}

const BlogInner = (props: Props) => {
  const { data, pageContext } = props;
  const { savedBlogs } = useStore();

  const blogInner = data.allSanityBlog.nodes[0];
  const {
    id,
    publishDate,
    category,
    content,
    title,
    relatedProductsNZ,
    relatedRecipes,
  } = blogInner;

  const { relatedblog } = data;
  let parentCategoryCrumb;
  if (category?.main?.title) {
    parentCategoryCrumb = {
      crumbLabel: category?.main?.title,
      pathname: `/blog/${category?.main?.slug?.current}`,
    };
  }
  const relatedblogFiltered = relatedblog.nodes.filter(relatedBlogItem => {
    if (relatedBlogItem == null) return false;
    return blogInner.slug?.current !== relatedBlogItem.slug?.current;
  });
  const margin = pageWidthMargin();
  return (
    <>
      <SEO seoData={blogInner.meta} slug={`blog/${blogInner.slug?.current}`} />
      <Wrapper>
        <Layout>
          <>
            <Container
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              margin="50px auto"
              maxWidth="1000px"
              position="relative"
            >
              <BreadcrumbContainer>
                <Breadcrumb
                  parentCrumb={parentCategoryCrumb}
                  pageContext={pageContext}
                  crumbLabel={title ?? undefined}
                />
              </BreadcrumbContainer>

              <Heading>
                <HeartIcon>
                  <img
                    src={
                      savedBlogs?.includes(id!)
                        ? assets.favouritedIcon
                        : assets.favouriteIcon
                    }
                    alt="icon-button"
                    width="17px"
                    height="17px"
                    onClick={() => handleSaveRecipeProduct(id, "blog")}
                  />
                </HeartIcon>

                <Container className="heading-text">
                  <H1 fontSize={38}>{title}</H1>
                  <P fontSize={fontSizes.p.default}>{publishDate}</P>
                </Container>
                {/* {image?.asset?.gatsbyImageData && (
              <Image imageData={image?.asset?.gatsbyImageData} />
            )} */}
              </Heading>
              {content?.blogContent?.map(content => {
                if (!content) return null;
                if (isSanityImageWithMeta(content)) {
                  return (
                    <Container
                      height="100%"
                      key={content?._key}
                      className="blogInnerImageContainer"
                    >
                      <Image
                        imageData={content?.asset?.gatsbyImageData}
                        mobileImage={
                          content?.mobileImage?.asset?.gatsbyImageData
                        }
                      />
                    </Container>
                  );
                }
                if (isSanityFullWidthTextBlock(content)) {
                  return (
                    <Container
                      flexDirection="column"
                      width="90%"
                      position="relative"
                      margin="auto"
                      backgroundRGBColor={content.backgroundColor?.value!}
                      padding={
                        content.backgroundColor?.value ? "30px 10px" : undefined
                      }
                    >
                      <BlocksContent blocks={content?.textBlock?._rawBlocks} />
                    </Container>
                  );
                }
                if (isSanityImageTextBlock(content)) {
                  return (
                    <ImageTextBlockContainer
                      key={content?._key}
                      reverse={content?.reverse}
                      backgroundColour={content?.backgroundColor?.value}
                    >
                      <ImageContainer reverse={content?.reverse}>
                        <Image
                          imageData={content?.image?.asset?.gatsbyImageData}
                        />
                      </ImageContainer>
                      <ContentBlocksContainer reverse={content?.reverse}>
                        <BlocksContent blocks={content?.text?._rawBlocks} />
                      </ContentBlocksContainer>
                    </ImageTextBlockContainer>
                  );
                }
                if (isSanityGallery(content)) {
                  return (
                    <GalleryCarousel content={content} key={content?._key} />
                  );
                }
                if (isSanityHtml(content)) {
                  return <Html content={content} key={content?._key} />;
                }
                if (isSanityVideo(content)) {
                  return <Video videoSrcURL={content.url} />;
                }
                return null;
              })}
            </Container>
            {relatedProductsNZ && relatedProductsNZ.length > 0 && (
              <Container
                width="100%"
                flexDirection="column"
                margin="50px 0 0 0"
              >
                <Container
                  flexDirection="column"
                  width="90%"
                  margin="auto"
                  position="relative"
                >
                  <CategoryPreview
                    products={relatedProductsNZ}
                    width={`calc(100% + ${margin})`}
                    margin={`0 -${margin} 0 0`}
                    tabletMargin={`0 -${margin} 0 0`}
                    showTabletNav={true}
                    title="Featured Products"
                  />
                </Container>
              </Container>
            )}

            {relatedRecipes && relatedRecipes.length > 0 && (
              <PaperContainer
                width="100%"
                flexDirection="column"
                margin="50px 0 0 0"
              >
                <Container flexDirection="column" width="90%" margin="auto">
                  <H3 margin="120px auto 0px 0px">Related Recipes</H3>
                  <Container
                    flexDirection="row"
                    margin="30px 0px 100px 0px"
                    isMobileColumn
                  >
                    {relatedRecipes.map(recipe => {
                      return (
                        <RecipePreview key={recipe?._key} recipe={recipe} />
                      );
                    })}
                  </Container>
                </Container>
              </PaperContainer>
            )}

            <Container flexDirection="column" width="90%" margin="50px auto">
              <H3>Related Posts</H3>
              <Container flexDirection="row" isMobileColumn width="100%">
                {relatedblogFiltered &&
                  relatedblogFiltered.length > 0 &&
                  relatedblogFiltered.slice(0, 4).map(blog => {
                    return <BlogPreview blog={blog} />;
                  })}
              </Container>
              {/* {relatedblogFiltered && (
                <WhatsNew
                  data={relatedblogFiltered.slice(0, 5)}
                  hideTitle
                  noContainerMargin
                />
              )} */}
            </Container>
          </>
        </Layout>
      </Wrapper>
    </>
  );
};

export default BlogInner;

export const query = graphql`
  query BlogInnerQuery($slug: String) {
    allSanityBlog(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...blogInnerQuery
      }
    }
    relatedblog: allSanityBlog(
      limit: 5
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        ...blogPreview
      }
    }
  }
`;
